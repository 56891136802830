import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Controller, useForm} from "react-hook-form";
import {createUser, resetState, updateUser} from "../redux/users/usersReducer";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {FormattedMessage} from "react-intl";
import {ROUTES} from "../router/route.constants";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getApiKey, setApiKey} from "../redux/settings/settingsReducer";
import ConfirmModal from "../components/modal/ConfirmModal";

export default function SettingsPage(){

    const navigate = useNavigate()

    const dispatch = useAppDispatch()

    const apiKey = useAppSelector(state => state.settings.apiKey)
    const [open, setOpen] = useState<boolean>(false)
    const [data, setData] = useState<any>(null)

    const {control, handleSubmit, setValue} = useForm({
        defaultValues: {
            apiKey: ""
        }
    })

    useEffect(() => {
        dispatch(getApiKey())
    },[])

    useEffect(() => {
        if(apiKey) setValue("apiKey", apiKey)
    },[apiKey])

    const onSubmit = (data: any) => {
        setOpen(true)
        setData(data)
    }

    const goBack = () => {
        navigate(ROUTES.SOS)
    }

    return(
        <Box sx={{width: "100%", height: "100%"}}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate
                 sx={{height: "100%", display: "flex", flexDirection: "column"}}>
                <Controller
                    name="apiKey"
                    control={control}
                    rules={{required: true, minLength: 4}}
                    render={({field, fieldState: {error}}) =>
                        <TextField
                            {...field}
                            error={!!error}
                            margin="normal"
                            required
                            fullWidth
                            id="apiKey"
                            label="apiKey"
                            name="apiKey"
                            autoComplete="apiKey"
                            autoFocus
                        />
                    }/>

                <Box sx={{display: "flex", justifyContent: "flex-end", columnGap: "10px", mt: "auto"}}>
                    <Button variant={"outlined"} onClick={() => goBack()}>
                        <FormattedMessage id={"cancel"}/>
                    </Button>
                    <Button variant={"contained"} type={"submit"}>
                        <FormattedMessage id={"save"}/>
                    </Button>
                </Box>
            </Box>
            <ConfirmModal
                onCancel={() => setOpen(false)}
                onConfirm={() => dispatch(setApiKey({data: data, callback: goBack}))}
                messageid={"change_apiKey_alert_message"}
                titleid={"attention"}
                open={open}
                confirmtextid={"change"}
                cancelbuttonprops={{
                    variant: "contained",
                }}
                confirmbuttonprops={{
                    variant: "outlined",
                    color: "error"
                }}
            />
        </Box>
    )
}