import itMessages from '../locales/it_IT.json';

const Itlang = {
    messages: {
        ...itMessages
    },
    locale: 'it-IT',
    data: require('@formatjs/intl-pluralrules/locale-data/it')
};

export default Itlang;