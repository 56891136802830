import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {FormattedMessage} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import {ROUTES} from "../router/route.constants";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {createUser, getUser, resetState, updateUser} from "../redux/users/usersReducer";

export default function UserForm() {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const {id} = useParams()

    const user = useAppSelector(state => state.users.user)

    const {control, handleSubmit, setValue, watch} = useForm({
        defaultValues: {
            id: null,
            username: '',
            email: '',
            password: "",
            confirmPassword: ""
        }
    })

    useEffect(() => {
        if (id) dispatch(getUser(id))
    }, [id])

    useEffect(() => {
        if (user) {
            setValue("id", user.id)
            setValue("username", user.username)
            setValue("email", user.email)
            setValue("password", "")
        }
    }, [user])


    const onSubmit = (data: any) => {
        if (data.id) {
            dispatch(updateUser({data: data, callback: goBack}))
        } else {
            dispatch(createUser({data: data, callback: goBack}))
        }
    }

    const goBack = () => {
        navigate(ROUTES.USERS)
        dispatch(resetState())
    }

    console.log(watch("password"))

    return (
        <Box sx={{width: "100%", height: "100%"}}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate
                 sx={{height: "100%", display: "flex", flexDirection: "column"}}>
                <Controller
                    name="username"
                    control={control}
                    rules={{required: true}}
                    render={({field, fieldState: {error}}) =>
                        <TextField
                            {...field}
                            error={!!error}
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                    }/>
                <Controller
                    name="email"
                    control={control}
                    rules={{required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g}}
                    render={({field, fieldState: {error}}) =>
                        <TextField
                            {...field}
                            error={!!error}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                        />
                    }/>
                <Controller
                    name={"password"}
                    control={control}
                    rules={{required: !user}}
                    render={({field, fieldState: {error}}) =>
                        <TextField
                            {...field}
                            error={!!error}
                            margin="normal"
                            fullWidth
                            required={!user}
                            name="password"
                            label={user ? "Nuova password" : "Password"}
                            type="password"
                            id="password"
                        />
                    }/>
                <Controller
                    name={"confirmPassword"}
                    control={control}
                    rules={{required: !user || watch("password") !== "", validate: (value, formValues) => {
                            if(formValues.password){
                                return formValues.password === value
                            }else{
                                return true
                            }
                        }}}
                    render={({field, fieldState: {error}}) =>
                        <TextField
                            {...field}
                            error={!!error}
                            margin="normal"
                            required={!user || watch("password") !== ""}
                            fullWidth
                            name="confirmPassword"
                            label="Conferma password"
                            type="password"
                            id="confirmPassword"
                            autoComplete="confirmPassword"
                        />
                    }/>
                <Box sx={{display: "flex", justifyContent: "flex-end", columnGap: "10px", mt: "auto"}}>
                    <Button variant={"outlined"} onClick={() => goBack()}>
                        <FormattedMessage id={"cancel"}/>
                    </Button>
                    <Button type={"submit"} variant={"contained"}>
                        <FormattedMessage id={"save"}/>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}