//export const apiUrl : string = "http://localhost:8080";
export const apiUrl : string = "https://sos.ilsognodelcapitano.it/api";

const endpoints = {
    login: apiUrl + "/auth/login",
    refreshToken: apiUrl + "/auth/refreshAuth",
    sosRealTime: apiUrl + "/sos/realTime",
    sosGetAll: apiUrl + "/sos/getAll",
    closeSos: apiUrl + "/sos/close",
    getDevices: apiUrl + "/device/getAll",
    deleteDevice: apiUrl + "/device/delete",
    createUser: apiUrl + "/employee",
    updateUser: apiUrl + "/employee",
    deleteUser: apiUrl + "/employee",
    getAllUsers: apiUrl + "/employee",
    getUser: apiUrl + "/employee",
    getApiKey: apiUrl + "/conf/apiKey",
    setApiKey: apiUrl + "/conf/apiKey",
}

export default endpoints;