import frMessages from '../locales/fr_FR.json';

const Frlang = {
    messages: {
        ...frMessages
    },
    locale: 'fr-FR',
    data: require('@formatjs/intl-pluralrules/locale-data/fr')
};

export default Frlang;