import React, {useEffect} from 'react';
import {IntlProvider} from 'react-intl';
import {ThemeProvider, Snackbar, Alert} from "@mui/material";
import AppLocale from 'config/translation';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {changeThemeStyle} from "redux/theme/themeReducer";
import {CustomTheme} from "config/themeOptions/ThemeOptions";
import {closeSnackbar, setAudioContext, setAudioEnabled} from "./redux/app/appReducer";
import dayjs from "dayjs";

interface Props {
    children: any
}

const AppProvider = ({children}: Props) => {
    const dispatch = useAppDispatch()

    const language = useAppSelector(state => state.languageSwitcher.language);
    const currentAppLocale = AppLocale[language as keyof typeof AppLocale];

    const themeStyle = useAppSelector(state => state.theme.themeStyle)
    const snackbarState = useAppSelector(state => state.app.snackbarState)
    const audioContext = useAppSelector(state => state.app.audioContext)

    useEffect(() => {
        let localStorageTheme = localStorage.getItem("current-theme")

        if (localStorageTheme === "light" || localStorageTheme === "dark") {
            dispatch(changeThemeStyle(localStorageTheme))
        } else {
            localStorage.setItem("current-theme", themeStyle)
        }
    }, [])

    const initAudioContext = () => {
        const context = new AudioContext()
        console.log(context)
        dispatch(setAudioContext(context))
        dispatch(setAudioEnabled(null))
    }

    //window.addEventListener("focus", (e) => console.log(e))

    useEffect(() => {
        if (!audioContext) {
            document.addEventListener("click", initAudioContext)

        }
        return () => document.removeEventListener("click", initAudioContext)
    }, [audioContext])


    useEffect(() => {
        localStorage.setItem("current-theme", themeStyle)
    }, [themeStyle])

    return (
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <ThemeProvider theme={CustomTheme[themeStyle as keyof typeof CustomTheme]}>
                {children}
                <Snackbar open={snackbarState.open} autoHideDuration={4000}
                          onClose={() => dispatch(closeSnackbar(null))}>
                    <Alert severity={snackbarState.type} sx={{width: '100%'}} variant={"filled"}>
                        {snackbarState.message}
                    </Alert>
                </Snackbar>
            </ThemeProvider>
        </IntlProvider>
    );
}

export default AppProvider;