import React from 'react';
import {Drawer, IconButton, List, styled, useMediaQuery, useTheme, Box} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {MainItemSideBar, SidebarProps} from "../../interfaces/Interfaces";
import Logo from 'assets/images/logo.svg'
import RecursiveSidebar from "./RecursiveSidebar";
import {ROLES, ROUTES} from "router/route.constants";
import {useIntl} from "react-intl";
import SosIcon from "@mui/icons-material/Sos";
import PersonIcon from '@mui/icons-material/Person';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import SettingsIcon from '@mui/icons-material/Settings';


const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    "& .emptySpace": {
        width: "40px"
    }
}));


function Sidebar(props: SidebarProps) {

    const {drawerWidth, open, setOpen} = props

    const customTheme = useTheme();
    const intl = useIntl();
    const matches = useMediaQuery(customTheme.breakpoints.up('lg'));


    const MenuItems: MainItemSideBar[] = [
        {
            icon: <SosIcon />,
            primaryText: intl.formatMessage({id: 'sidebar_sos'}),
            divider: true,
            path: ROUTES.SOS,
            roles: [ROLES.EMPLOYEE, ROLES.SUPER_ADMIN]
        },
        {
            icon: <PersonIcon />,
            primaryText: intl.formatMessage({id: 'sidebar_user'}),
            divider: true,
            path: ROUTES.USERS,
            roles: [ROLES.SUPER_ADMIN]
        },
        {
            icon: <HistoryToggleOffIcon />,
            primaryText: intl.formatMessage({id: 'sidebar_history'}),
            divider: true,
            path: ROUTES.SOS_HYSTORY,
            roles: [ROLES.SUPER_ADMIN]
        },
        {
            icon: <SettingsIcon />,
            primaryText: intl.formatMessage({id: 'sidebar_settings'}),
            divider: true,
            path: ROUTES.SETTINGS,
            roles: [ROLES.SUPER_ADMIN]
        },
    ]


    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    overflow: "auto"
                },
            }}
            variant={matches ? "persistent" : "temporary"}
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
        >
            <DrawerHeader>
                <div className="emptySpace"/>
                <Box src={Logo} component={"img"} sx={{maxHeight: "64px", padding: "5px", marginLeft: "auto", marginRight: "auto"}}/>
                <IconButton onClick={() => setOpen(!open)}>
                    {customTheme.direction === 'ltr' && <ChevronLeftIcon/>}
                </IconButton>
            </DrawerHeader>
            <List
                sx={{width: '100%', maxWidth: 360}}
                component="nav"
                aria-labelledby="nested-list-subheader"
                dense
            >
                <RecursiveSidebar menuItems={MenuItems} padding={2}/>
            </List>
        </Drawer>
    );
}

export default Sidebar;