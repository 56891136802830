import endpoints from "./endpoints";
import {AccessToken} from "../interfaces/Interfaces";
import jwtDecode from "jwt-decode";
import {Mutex} from "async-mutex";

interface SuperFetchParams {
    method: "get" | "post" | "delete" | "put" | "PATCH",
    endpoint: string,
    data?: any,
    authenticate?: boolean,
    customHeaderToAdd?: any
}

const authorizedHeader = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token') || undefined,
});

const baseHeader = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
})

export const mutex = new Mutex();


export const SuperFetch = async (props: SuperFetchParams) => {
    //if mutex is lock wait
    await mutex.waitForUnlock()

    let requestConfig: any = {
        credentials: 'include',
        method: props.method
    };

    //check if request is authenticated and refresh token is not expired
    if (props.authenticate && Math.floor(Date.now() / 1000) >= parseInt(localStorage.getItem("refresh_token_exp")!)) {
        //if refresh token is expired redirect to keycloak login
        localStorage.clear()
        return
    }
    if (props.data) {
        requestConfig.body = JSON.stringify(props.data)
    }
    if (props.customHeaderToAdd != null) {
        requestConfig.headers = {...requestConfig.headers, ...props.customHeaderToAdd}
    }

    //check if request is authenticated, token is not expired and no one is refreshing token
    if (props.authenticate && Math.floor(Date.now() / 1000) >= parseInt(localStorage.getItem("token_exp")!) && !mutex.isLocked()) {

        //blocking other fetch
        const release = await mutex.acquire();

        let refreshConfig: any = {
            credentials: 'include',
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }

        let response = await fetch(endpoints.refreshToken + "?refresh_token=" + localStorage.getItem("refresh_token"), refreshConfig)

        switch (response.status) {
            case 200:
                let json = await response.json()
                let decoded: AccessToken = jwtDecode(json.access_token)
                localStorage.setItem("token", json.access_token)
                localStorage.setItem("token_exp", decoded.exp.toString())
                //unlock other fetch
                release()
                break
            case 401:
                localStorage.clear()
                break
        }
    }
    //if mutex is lock wait
    await mutex.waitForUnlock()
    if (props.authenticate) {
        requestConfig.headers = authorizedHeader();
    } else {
        requestConfig.headers = baseHeader();
    }
    return _doFetch(props.method, props.endpoint, props.data, props.authenticate, requestConfig)

};

function _doFetch(method: string, url: string, data?: any, authenticate?: boolean, requestConfig?: any) {
    return fetch(url, requestConfig)
        .then(response => {
            return handleResponse(response)
        })
        .catch(error => {
            return {
                error: error
            }
        });
}

async function handleResponse(response: Response) {
    let json;
    if (response.status === 200 ||
        response.status === 201 ||
        response.status === 202 ||
        response.status === 203 ||
        response.status === 204 ||
        response.status === 205 ||
        response.status === 206 ||
        response.status === 207 ||
        response.status === 208 ||
        response.status === 226) {

        try{
            json = await response.json()
        }catch(err){
            return {response: response.status}
        }
        return {response: json}
    } else {
        return {error: response}
    }

}

export default SuperFetch
