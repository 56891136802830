import {createAction, createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {LoginSuccessPayload} from "../../interfaces/Interfaces";
import {revertAll} from "../store";

// Define a type for the slice state
interface authState {
    token : string,
    role: string,
}

// Define the initial state using that type
const initialState: authState = {
    token: null,
    role: null,
}


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {},
        loginSuccess: (state, action: PayloadAction<LoginSuccessPayload>) => {
            state.token = action.payload.token
            state.role = action.payload.role
        },
        logout: () => {},
        checkAuthorization: () => {}
    },
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
})

export const {
    login,
    loginSuccess,
    logout,
    checkAuthorization
} = authSlice.actions


export default authSlice.reducer