import {all, put, takeLatest, select, fork, call, take} from "redux-saga/effects";
import {eventChannel, END} from "redux-saga"
import SuperFetch, {mutex} from "../../utils/SuperFetch";
import endpoints from "../../utils/endpoints";
import {
    subscribe,
    unsubscribe,
    reconnectToSse,
    channelSelector,
    setChannel,
    newSos,
    newSosClosed,
    getDeviceRegistered, getDeviceRegisteredSuccess, closeSos, deleteDevice, deleteDeviceSuccess, getAll, getAllSuccess
} from "./sosReducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {openSnackbar} from "../app/appReducer";

function* subscribeSaga() {
    yield takeLatest(subscribe.type, function* () {

        yield mutex.waitForUnlock()

        if (Math.floor(Date.now() / 1000) >= parseInt(localStorage.getItem("token_exp")) && !mutex.isLocked()) {

            //blocking other fetch
            const release = yield mutex.acquire();

            let refreshConfig: any = {
                credentials: 'include',
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            }

            const response = yield fetch(endpoints.refreshToken + "?refresh_token=" + localStorage.getItem("refresh_token"), refreshConfig)

            if (response) {
                switch (response.status) {
                    case 200:
                        let json = yield response.json()
                        localStorage.setItem("token", json.access_token)
                        localStorage.setItem("token_exp", json.expires_at)
                        break
                    case 401:
                        localStorage.clear()
                        break
                }
            }

            release()
        }

        yield mutex.waitForUnlock()

        try {
            let token = localStorage.getItem("token")
            // @ts-ignore
            let eventSource = new EventSourcePolyfill(endpoints.sosRealTime, {
                withCredentials: true,
                headers: {
                    'Authorization': "Bearer " + token
                }
            })

            const channel = yield call(subscribeToSSE, eventSource, ["new-sos", "new-sos-closed"])
            yield put(setChannel(channel));

            while (true) {
                const event = yield take(channel);
                console.log("new event", event)
                if (event.type === "new-sos") {
                    let data = JSON.parse(String(event.data));

                    yield put(newSos(data));
                }

                if(event.type === "new-sos-closed"){
                    let data = JSON.parse(String(event.data))

                    yield put(newSosClosed(data))
                }

                if (event.type === "error" && event.status === 401) {
                    yield put(reconnectToSse(null))
                }
            }
        } catch (e) {
            console.error(e)
        }
    })
}

function* unsubscribeSaga() {
    yield takeLatest(unsubscribe.type, function* (data) {
        const channel = yield select(channelSelector)
        if(channel) channel.close()
    })
}

function* reconnectToSseSaga() {
    yield takeLatest(reconnectToSse.type, function* (data) {
        const channel = yield select(channelSelector)
        channel.close()
        yield put(subscribe(null))
    })
}

function* getDeviceRegisteredSaga(){
    yield takeLatest(getDeviceRegistered.type, function* () {
        const {response, error} = yield call(SuperFetch, {
            method: "get",
            authenticate: true,
            endpoint: endpoints.getDevices,
        })

        if(response){
            yield put({
                type: getDeviceRegisteredSuccess.type,
                payload: response
            })
        }else if(error){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "error",
                    message: "Errore inaspettato!"
                }
            })

            console.error(error)
        }

    })
}

function* closeSosSaga(){
    yield takeLatest(closeSos.type, function* (action: PayloadAction<{deviceId: string, sosId: string}>) {
        const {response, error} = yield call(SuperFetch, {
            method: "PATCH",
            authenticate: true,
            endpoint: endpoints.closeSos + "?sosId=" + action.payload.sosId,
        })

        if(response){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "success",
                    message: "Sos chiuso con successo!"
                }
            })
        }else if(error){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "error",
                    message: "Errore inaspettato!"
                }
            })

            console.error(error)
        }

    })
}

function* deleteDeviceSaga() {
    yield takeLatest(deleteDevice.type, function* (action: PayloadAction<String>) {
        const {response, error} = yield call(SuperFetch, {
            method: "delete",
            authenticate: true,
            endpoint: endpoints.deleteDevice + "?id=" + action.payload,
        })

        if (response) {
            yield put({
                type: deleteDeviceSuccess.type,
                payload: action.payload
            })

            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "success",
                    message: "Device eliminato"
                }
            })
        }else if(error){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "error",
                    message: "Errore inaspettato!"
                }
            })

            console.error(error)
        }

    })
}

function* getAllSaga(){
    yield takeLatest(getAll.type, function* (action: PayloadAction<String>) {
        const {response, error} = yield call(SuperFetch, {
            method: "get",
            authenticate: true,
            endpoint: endpoints.sosGetAll,
        })

        if(response){
            yield put({
                type: getAllSuccess.type,
                payload: response
            })
        }else if(error){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "error",
                    message: "Errore inaspettato!"
                }
            })

            console.error(error)
        }

    })
}


export default function* sosSaga() {
    yield all(
        [
            fork(subscribeSaga),
            fork(unsubscribeSaga),
            fork(reconnectToSseSaga),
            fork(getDeviceRegisteredSaga),
            fork(closeSosSaga),
            fork(deleteDeviceSaga),
            fork(getAllSaga)
        ]
    );
}

export function subscribeToSSE(eventSrc: EventSource, eventTypes: string[]) {
    return eventChannel((emitter) => {
        eventSrc.onopen = (ev: any) => {
            console.info('connection is established');
            emitter(ev);
        };

        eventSrc.onerror = (err: any) => {
            console.error(err);
            emitter(err)
        };

        eventTypes.forEach(eventType => {
            eventSrc.addEventListener(eventType, (ev: any) => {
                emitter(ev);
            });
        })

        return () => {
            console.info('closing connection...');
            eventSrc.close();
            emitter(END);
        };
    });
}