import enMessages from '../locales/en_US.json';

const Enlang = {
    messages: {
        ...enMessages
    },
    locale: 'en-US',
    data: require('@formatjs/intl-pluralrules/locale-data/en')
};

export default Enlang;