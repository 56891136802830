import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getAll} from "../redux/sos/sosReducer";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration"

dayjs.extend(duration)

export default function SosHistory(){

    const dispatch = useAppDispatch()

    const sosList = useAppSelector(state => state.sos.sosList)

    const columns: GridColDef[] = [
        {
            field: 'state',
            headerName: 'Stato',
            flex: 1,
            minWidth: 150
        },
        {
            field: 'dateCreated',
            headerName: 'Data di apertura',
            flex: 1,
            valueGetter: params => dayjs(params.value).format("DD/MM/YYYY HH:mm:ss"),
            minWidth: 200
        },
        {
            field: 'lastUpdated',
            headerName: 'Data di chiusura',
            flex: 1,
            valueGetter: params => dayjs(params.value).format("DD/MM/YYYY HH:mm:ss"),
            minWidth: 200
        },
        {
            field: "duration",
            headerName: 'Durata',
            flex: 1,
            valueGetter: params => dayjs.duration(params.row.lastUpdated - params.row.dateCreated).format("HH:mm:ss"),
            minWidth: 150
        },
        {
            field: 'device',
            headerName: 'Dispositivo',
            flex: 1,
            valueGetter: params =>  params.value.displayName,
            minWidth: 150
        },
    ]

    useEffect(() => {
        dispatch(getAll())
    },[])

    return(
        <Box sx={{ height: "100%", width: '100%' }}>
            <DataGrid
                rows={sosList}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                }}
                pageSizeOptions={[20]}
            />
        </Box>
    )
}