import { all, fork } from "redux-saga/effects";
import authSaga from "./auth/authSaga";
import sosSaga from "./sos/sosSaga";
import usersSaga from "./users/usersSaga";
import settingsSaga from "./settings/settingsSaga";

export default function* rootSaga() {
    yield all([fork(authSaga)])
    yield all([fork(sosSaga)])
    yield all([fork(usersSaga)])
    yield all([fork(settingsSaga)])
}
