import React, {MouseEventHandler} from "react";
import {Button, Dialog, DialogProps, styled} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {FormattedMessage} from "react-intl";
import {ButtonProps} from "@mui/material/Button";


export interface ConfirmModalProps extends DialogProps{
    titleid: string,
    messageid: string,
    cancelbuttonprops?: ButtonProps,
    confirmbuttonprops?: ButtonProps,
    canceltextid: string,
    confirmtextid: string
    onCancel: MouseEventHandler<HTMLButtonElement>,
    onConfirm: MouseEventHandler<HTMLButtonElement>
}

const CustomDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            minWidth: "400px",
            width: "100%",
            maxWidth: "500px",
            minHeight: "150px",
            borderRadius: "4px",
        },
    },
}))

const CustomDialogContent = styled(DialogContent)(() => ({
    display: "flex",
    justifyContent: "center"
}))

export default function ConfirmModal(props: ConfirmModalProps) {
    return (
        <CustomDialog {...props}>
            <DialogTitle>
                <FormattedMessage id={props.titleid}/>
            </DialogTitle>
            <CustomDialogContent>
                <FormattedMessage id={props.messageid}/>
            </CustomDialogContent>
            <DialogActions>
                <Button variant={"outlined"} {...props.cancelbuttonprops} onClick={props.onCancel}>
                    <FormattedMessage id={props.canceltextid}/>
                </Button>
                <Button variant={"contained"} color={"success"} {...props.confirmbuttonprops} onClick={props.onConfirm}>
                    <FormattedMessage id={props.confirmtextid}/>
                </Button>
            </DialogActions>
        </CustomDialog>
    )
}

ConfirmModal.defaultProps = {
    canceltextid: "cancel",
    confirmtextid: "confirm"
}