import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {revertAll} from "../store";
import {User} from "../../interfaces/Interfaces";

// Define a type for the slice state
interface usersState {
    userList: User[],
    user: User
}

// Define the initial state using that type
const initialState: usersState = {
    userList: [],
    user: null
}


export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        createUser: (state, action: PayloadAction<{data: User, callback: () => void}>) => {},
        createUserSuccess: () => {},
        updateUser: (state, action: PayloadAction<{data: User, callback: () => void}>) => {},
        updateUserSuccess: () => {},
        deleteUser: (state, action) => {},
        getAllUsers: () => {},
        getAllUsersSuccess: (state, action: PayloadAction<User[]>) => {
            state.userList = action.payload
        },
        getUser: (state, action) => {},
        getUserSuccess: (state, action: PayloadAction<User>) => {
            state.user = action.payload
        },
        resetState: () => initialState
    },
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
})


export const {
    createUser,
    createUserSuccess,
    getAllUsers,
    getAllUsersSuccess,
    getUser,
    getUserSuccess,
    updateUser,
    updateUserSuccess,
    deleteUser,
    resetState
} = usersSlice.actions


export default usersSlice.reducer