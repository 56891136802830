import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getDeviceRegistered, subscribe, unsubscribe, closeSos, deleteDevice} from "../redux/sos/sosReducer";
import {Box} from "@mui/material";
import {
    Card,
    Grid,
    Typography,
    Button,
    styled,
    ButtonProps,
    useTheme
} from "@mui/material";
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CloseIcon from '@mui/icons-material/Close';
import SosIcon from '@mui/icons-material/Sos';
import DoneIcon from '@mui/icons-material/Done';
import dayjs from "dayjs";
import {DeviceCardProps, SosCardProps} from "../interfaces/Interfaces";
import {ROLES} from "../router/route.constants";
import ConfirmModal from "../components/modal/ConfirmModal";

export default function SosDashboard() {

    const dispatch = useAppDispatch()
    const deviceList = useAppSelector(state => state.sos.deviceList)
    const channel = useAppSelector(state => state.sos.channel)
    const audioEnabled = useAppSelector(state => state.app.audioEnabled)
    const [audio] = useState(new Audio("/alarm.mp3"))
    const [playing, setPlaying] = useState(false);

    useEffect(() => {
        audio.muted = !playing;
    }, [playing])


    useEffect(() => {
        if (deviceList.length !== 0) {
            if (!deviceList.every(it => !it.lastSos)) {
                console.log("playing")
                setPlaying(true)
            } else {
                console.log("stopping")
                setPlaying(false)
            }
        }
    }, [deviceList])

    useEffect(() => {
        if (audioEnabled) {
            audio.muted = true
            audio.play().catch(e => console.log(e))
            dispatch(subscribe(null))
            dispatch(getDeviceRegistered(null))
        }
    }, [audioEnabled])

    const removeGap = (e) => {
        let buffer = .33
        // @ts-ignore
        if (e.currentTarget.currentTime > e.currentTarget.duration - buffer) {
            // @ts-ignore
            e.currentTarget.currentTime = 0
        }
    }

    const onFocusIn = () => {
        if(!channel) {
            dispatch(subscribe(null))
            dispatch(getDeviceRegistered(null))
        }
    }

    useEffect(() => {
        audio.addEventListener('timeupdate', removeGap);

        return () => {
            if (channel) dispatch(unsubscribe(null))
            audio.removeEventListener("timeupdate", removeGap)
            audio.pause()
        }
    }, [])

    useEffect(() => {
        window.addEventListener("focus", onFocusIn)

        return () => window.removeEventListener("focus", onFocusIn)
    },[channel])

    return (
        <Box sx={{height: "100%", width: "100%"}}>
            {audioEnabled ?
                <>
                    <h1>Dashboard</h1>
                    <Grid container spacing={2}>
                        {deviceList.length !== 0 && deviceList.map((it, index) => (
                            <Grid xs={12} md={6} xl={4} key={"device_" + index} item>
                                {it.lastSos && it.lastSos.state === "OPEN" ?
                                    <SosCard device={it}
                                             onClose={(e) => {
                                                 debugger
                                                 dispatch(closeSos({
                                                     deviceId: it.id,
                                                     sosId: it.lastSos.id
                                                 }))
                                             }}/>
                                    :
                                    <DeviceCard device={it} onDelete={() => dispatch(deleteDevice(it.id))}/>
                                }
                            </Grid>
                        ))}
                    </Grid>
                </> :
                <>
                    <Box sx={{height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Typography variant={"h4"}>Per abilitare l'audio clicca in un punto qualsiasi dello schermo</Typography>
                    </Box>
                </>
            }
        </Box>
    )
}

const CustomIconButton = styled(Button)<ButtonProps>(({theme}) => ({
    padding: 6,
    minWidth: "unset",
    borderRadius: "50%"
}))

export function DeviceCard(props: DeviceCardProps) {

    const {device} = props

    const role = useAppSelector(state => state.auth.role)
    const isSuperAdmin = role === ROLES.SUPER_ADMIN
    const [open, setOpen] = useState<boolean>(false)

    return (
        <Card variant="outlined">
            <Box sx={{
                padding: "30px 20px",
                display: "flex",
                alignItems: "center",
                columnGap: "10px"
            }}
            >
                <BeachAccessIcon sx={{fontSize: "50px"}} color={"primary"}/>
                <Typography variant={"h4"}>
                    {device.displayName}
                </Typography>
                {isSuperAdmin && <CustomIconButton sx={{marginLeft: "auto"}} variant={"contained"} color={"error"}
                                                   onClick={() => setOpen(true)}>
                    <CloseIcon sx={{fontSize: "40px"}}/>
                </CustomIconButton>}
            </Box>
            <ConfirmModal
                onCancel={() => setOpen(false)}
                onConfirm={(e) => {
                    setOpen(false)
                    props.onDelete(e)
                }}
                messageid={"delete_device_alert_message"}
                titleid={"attention"}
                open={open}
                confirmtextid={"delete"}
                cancelbuttonprops={{
                    variant: "contained",
                }}
                confirmbuttonprops={{
                    variant: "outlined",
                    color: "error"
                }}
            />
        </Card>
    )
}

export function SosCard(props: SosCardProps) {

    const {device} = props

    const theme = useTheme()

    const BlinkCard = styled(Card)(({theme}) => ({
        backgroundColor: theme.palette.error.main,
        animation: "blinker 2s linear infinite",
        "@keyframes blinker": {
            "50%": {
                backgroundColor: "#FC7529"
            }
        }
    }))

    return (
        <BlinkCard variant="outlined">
            <Box sx={{
                padding: "30px 20px",
                display: "flex",
                alignItems: "center",
                columnGap: "30px"
            }}
            >
                <SosIcon sx={{fontSize: "50px", color: theme.palette.white.main}}/>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
                >
                    <Typography variant={"h4"} color={theme.palette.error.contrastText} lineHeight={1}>
                        {device.displayName}
                    </Typography>
                    <Typography color={theme.palette.error.contrastText} lineHeight={1}>
                        {dayjs(device.lastSos.dateCreated).format("DD/MM/YYYY HH:mm")}
                    </Typography>
                </Box>
                <CustomIconButton sx={{marginLeft: "auto"}} variant={"contained"} color={"secondary"}
                                  onClick={props.onClose}>
                    <DoneIcon sx={{fontSize: "40px"}}/>
                </CustomIconButton>
            </Box>
        </BlinkCard>
    )
}