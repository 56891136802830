import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {
    setApiKey,
    getApiKey,
    getApiKeySuccess
} from "./settingsReducer";
import SuperFetch from "../../utils/SuperFetch";
import endpoints from "../../utils/endpoints";
import {openSnackbar} from "../app/appReducer";

function* getApiKeySaga(){
    yield takeLatest(getApiKey.type, function* (action: PayloadAction<any>) {
        const {response, error} = yield call(SuperFetch, {
            method: "get",
            authenticate: true,
            endpoint: endpoints.getApiKey
        })

        if(response){
            yield put({
                type: getApiKeySuccess.type,
                payload: response
            })
        }else if(error){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "error",
                    message: "Errore inaspettato!"
                }
            })

            console.error(error)
        }
    })
}

function* setApiKeySaga(){
    yield takeLatest(setApiKey.type, function* (action: PayloadAction<any>) {
        const {response, error} = yield call(SuperFetch, {
            method: "post",
            authenticate: true,
            endpoint: endpoints.setApiKey,
            data: action.payload.data
        })

        if(response){
            if(action.payload.callback){
                action.payload.callback()
            }

            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "success",
                    message: "Api key cambiata con successo"
                }
            })
        }else if(error){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "error",
                    message: "Errore inaspettato!"
                }
            })

            console.error(error)
        }
    })
}



export default function* settingsSaga(){
    yield all([
        fork(getApiKeySaga),
        fork(setApiKeySaga),
    ])
}