import saMessages from '../locales/ar_SA.json';

const Salang = {
    messages: {
        ...saMessages
    },
    locale: 'ar',
    data: require('@formatjs/intl-pluralrules/locale-data/ar')
};

export default Salang;