import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Logo from 'assets/images/logo.svg'
import {useForm, Controller} from "react-hook-form";
import {login} from "../redux/auth/authReducer";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../router/route.constants";

export default function LoginPage() {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const token = useAppSelector(state => state.auth.token)

    const { control, handleSubmit } = useForm({
        defaultValues: {
            username: '',
            password: {}
        }
    });

    const onSubmit = (data: any) => {
        dispatch(login(data))
    };

    useEffect(() => {
        if(token){
            navigate(ROUTES.SOS)
        }
    },[token])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box component={"img"} src={Logo} sx={
                    {
                        display: "block",
                        maxWidth: "300px",
                        maxHeight: "120px",
                        width: "auto",
                        height: "auto",
                    }}
                />
                <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 1}}>
                    <Controller
                        name="username"
                        control={control}
                        rules={{required: true}}
                        render={({field, fieldState: {error}}) =>
                            <TextField
                                {...field}
                                error={!!error}
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                            />
                    }/>
                    <Controller
                        name={"password"}
                        control={control}
                        rules={{required: true}}
                        render={({field, fieldState: {error}}) =>
                            <TextField
                                {...field}
                                error={!!error}
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                    }/>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}