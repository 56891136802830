import {configureStore, combineReducers, createAction} from '@reduxjs/toolkit'
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import languageSwitcher from "./languageSwitcher/languageSwitcherSlice";
import themeSlice from "redux/theme/themeReducer";
import authSlice from "./auth/authReducer";
import appSlice from "./app/appReducer";
import sosSlice from "./sos/sosReducer";
import usersSlice from "./users/usersReducer";
import settingsSlice from "./settings/settingsReducer";

let sagaMiddleware = createSagaMiddleware();

export const revertAll = createAction('REVERT_ALL')

const store = configureStore({
    reducer: {
        languageSwitcher : languageSwitcher,
        theme: themeSlice,
        auth: authSlice,
        app: appSlice,
        sos: sosSlice,
        users: usersSlice,
        settings: settingsSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
        {serializableCheck: false}
    ).concat(sagaMiddleware)
})

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
