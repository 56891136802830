import {createTheme} from "@mui/material";

export const LightTheme = createTheme({
    palette: {
        primary: {
            main: "#1B6B93"
        },
        secondary: {
            main: "#FFDEB9",
        },
        info: {
            main: "#0288D1",
        },
        warning: {
            dark: "#FE7A00",
            main: "#FFA700",
            light: "#FFC000",
            contrastText: "#FFF",
        },
        error: {
            main: "#FC2947",
        },
        white: {
            main: "#FFFFFF"
        }
    },
})


export const CustomTheme = {
    light: {...LightTheme}
}

declare module '@mui/material/styles' {

    interface Palette {
        white: Palette["primary"];
    }

    interface PaletteOptions {
        white: PaletteOptions["primary"]
    }
}
