import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {GridColDef, DataGrid, GridRowParams, GridActionsCellItem, GridToolbarContainer} from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router-dom";
import {ROLES, ROUTES} from "../router/route.constants";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getAllUsers, deleteUser} from "../redux/users/usersReducer";
import {setApiKey} from "../redux/settings/settingsReducer";
import ConfirmModal from "../components/modal/ConfirmModal";



export default function UsersPage(){

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const users = useAppSelector(state => state.users.userList)
    const [open, setOpen] = useState<boolean>(false)
    const [id, setId] = useState<string>(null)

    const columns: GridColDef[] = [
        {
            field: 'username',
            headerName: 'Username',
            flex: 2
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 2
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem icon={<EditIcon/>} onClick={(e) => navigate(ROUTES.USER + "/" + params.row.id)} label="Edit" />,
                <GridActionsCellItem icon={<DeleteIcon/>} onClick={(e) => {
                    setOpen(true)
                    setId(params.row.id)
                } } label="Delete"/>,
            ]
        }
    ]

    useEffect(() => {
        dispatch(getAllUsers(null))
    },[])

    return(
        <Box sx={{ height: "100%", width: '100%' }}>
            <DataGrid
                rows={users}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                }}
                slots={{
                    toolbar: CustomToolBar
                }}
                pageSizeOptions={[20]}
            />
            <ConfirmModal
                onCancel={() => setOpen(false)}
                onConfirm={() => {
                    dispatch(deleteUser(id))
                    setId(null)
                    setOpen(false)
                }}
                messageid={"delete_user_alert_message"}
                titleid={"attention"}
                open={open}
                confirmtextid={"delete"}
                cancelbuttonprops={{
                    variant: "contained",
                }}
                confirmbuttonprops={{
                    variant: "outlined",
                    color: "error"
                }}
            />
        </Box>
    )
}

function CustomToolBar(){
    const navigate = useNavigate()

    return(
        <GridToolbarContainer sx={{display: "flex", justifyContent: "flex-end", padding: "10px 10px 0 10px"}}>
            <Button variant={"contained"} onClick={() => navigate(ROUTES.USER)}>
                <FormattedMessage id="new_user"/>
            </Button>
        </GridToolbarContainer>
    )
}