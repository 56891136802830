import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {checkAuthorization, login, loginSuccess, logout} from "./authReducer";
import {PayloadAction} from "@reduxjs/toolkit";
import SuperFetch from "../../utils/SuperFetch";
import endpoints from "../../utils/endpoints";
import {openSnackbar} from "../app/appReducer";
import {revertAll} from "../store";

export function isTokenExpired() {

    let expires_at = parseInt(localStorage.getItem('token_exp'));
    if (!expires_at) {
        expires_at = 0
    }
    let currentTimestamp = new Date().getTime();

    return currentTimestamp >= expires_at;
}

export function getToken() {
    return localStorage.getItem('token');
}

export function getRole() {
    return localStorage.getItem('role')
}


function* loginSaga() {
    yield takeLatest(login.type, function* (action: PayloadAction<any>) {

        const {response, error} = yield call(SuperFetch, {
            method: "post",
            authenticate: false,
            endpoint: endpoints.login,
            data: action.payload
        })

        if(response){
            localStorage.clear()
            localStorage.setItem("token", response.access_token)
            localStorage.setItem("token_exp", response.expires_at)
            localStorage.setItem("id", response.id)
            localStorage.setItem("refresh_token", response.refresh_token)
            localStorage.setItem("role", response.role)
            localStorage.setItem("username", response.username)

            yield put({
                type: loginSuccess.type,
                payload: {token: response.access_token, role: response.role}
            })

            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "success",
                    message: "Login avvenuta con successo"
                }
            })

        }else if(error){
            if(error.status === 401){
                yield put({
                    type: openSnackbar.type,
                    payload: {
                        type: "error",
                        message: "Username o password errati"
                    }
                })
            }
        }
    })
}

export function* checkAuthorizationSaga() {
    yield takeLatest(checkAuthorization.type, function* () {
        if (isTokenExpired()) {
            localStorage.clear()
        } else {
            const token = getToken();
            const role = getRole();

            if (token) {
                yield put({
                    type: loginSuccess.type,
                    payload: {token: token, role: role}
                })
            }
        }
    })

}

export function* logoutSaga() {
    yield takeLatest(logout.type, function* () {
        localStorage.clear()

        yield put({
            type: revertAll.type
        })
    })
}

export default function* authSaga(){
    yield all([
        fork(loginSaga),
        fork(checkAuthorizationSaga),
        fork(logoutSaga)
    ])
}