import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {revertAll} from "../store";

// Define a type for the slice state
interface settingsState {
    apiKey: string
}

// Define the initial state using that type
const initialState: settingsState = {
    apiKey: null
}


export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        getApiKey: () => {},
        getApiKeySuccess: (state, action) => {
            state.apiKey = action.payload.apiKey
        },
        setApiKey: (state, action) => {}
    },
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
})


export const {
    getApiKey,
    getApiKeySuccess,
    setApiKey
} = settingsSlice.actions


export default settingsSlice.reducer