import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {revertAll} from "../store";

interface themeState {
    themeStyle: "light" | "dark",
}

const initialState: themeState = {
    themeStyle: "light"
}


export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        changeThemeStyle: (state, action: PayloadAction<"light" | "dark">) => {
            state.themeStyle = action.payload
        },
    },
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
})

export const {
    changeThemeStyle
} = themeSlice.actions

export default themeSlice.reducer