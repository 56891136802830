import React from 'react';
import {RecursiveSidebarProps} from "../../interfaces/Interfaces";
import SidebarElement from "./SidebarElement";
import {useAppSelector} from "../../redux/hooks";


function RecursiveSidebar(props: RecursiveSidebarProps) {

    const {menuItems} = props

    const role = useAppSelector(state => state.auth.role)

    return (
        <>
            {menuItems.filter(it => it.roles.includes(role)).map((element) => {
                return (
                    <SidebarElement element={element} padding={props.padding} key={element.primaryText}/>
                )
            })}
        </>
    )
}

export default RecursiveSidebar;