import Enlang from './entries/en_US';
import Itlang from './entries/it_IT';
import Eslang from './entries/es_ES';
import Frlang from './entries/fr_FR';
import Delang from './entries/de_DE';
import Salang from './entries/ar_SA';

const AppLocale = {
    en: Enlang,
    it: Itlang,
    es: Eslang,
    fr: Frlang,
    de: Delang,
    sa: Salang
};

export default AppLocale;