import deMessages from '../locales/de_DE.json';

const Delang = {
    messages: {
        ...deMessages
    },
    locale: 'de-DE',
    data: require('@formatjs/intl-pluralrules/locale-data/de')
};

export default Delang;