import React from 'react';
import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, useTheme} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {AppBarProps, TopbarProps} from "../../interfaces/Interfaces";
import MoreIcon from "@mui/icons-material/MoreVert";
import {Logout} from '@mui/icons-material';
import {FormattedMessage} from 'react-intl';
import {useAppDispatch} from 'redux/hooks';
import {logout} from 'redux/auth/authReducer';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, drawerwidth }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerwidth}px)`,
        marginLeft: `${drawerwidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


function Topbar(props: TopbarProps) {

    const { drawerWidth, open, setOpen } = props

    const customTheme = useTheme();

    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const openPopover = Boolean(anchorEl);
    const id = open ? 'option-popover' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logout(null))
        handleClose()
    }

    return (
        <AppBar drawerwidth={drawerWidth} open={open}>
            <Toolbar sx={{ backgroundColor: customTheme.palette?.primary.dark }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpen(true)}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton key={"optionButton"} size="large" color="inherit" onClick={handleClick}>
                    <MoreIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openPopover}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout/>
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id='logout'/>
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

export default Topbar;