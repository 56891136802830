import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {
    createUser, createUserSuccess, deleteUser,
    getAllUsers,
    getAllUsersSuccess,
    getUser,
    getUserSuccess,
    updateUser,
    updateUserSuccess
} from "./usersReducer";
import SuperFetch from "../../utils/SuperFetch";
import endpoints from "../../utils/endpoints";
import {openSnackbar} from "../app/appReducer";

function* createUserSaga(){
    yield takeLatest(createUser.type, function* (action: PayloadAction<any>) {
        const {response, error} = yield call(SuperFetch, {
            method: "post",
            authenticate: true,
            endpoint: endpoints.createUser,
            data: action.payload.data
        })

        if(response){
            yield put({
                type: createUserSuccess.type,
                payload: response
            })

            if(action.payload.callback){
                action.payload.callback()
            }

            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "success",
                    message: "Utente creato!"
                }
            })
        }else if(error){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "error",
                    message: "Errore inaspettato!"
                }
            })

            console.error(error)
        }
    })
}

function* updateUserSaga(){
    yield takeLatest(updateUser.type, function* (action: PayloadAction<any>) {
        const {response, error} = yield call(SuperFetch, {
            method: "PATCH",
            authenticate: true,
            endpoint: endpoints.updateUser + "/" + action.payload.data.id,
            data: action.payload.data
        })

        if(response){
            yield put({
                type: updateUserSuccess.type,
                payload: response
            })

            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "success",
                    message: "L'utente " + action.payload.data.username + " è stato aggiornato!"
                }
            })

            if(action.payload.callback){
                action.payload.callback()
            }
        }else if(error){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "error",
                    message: "Errore inaspettato!"
                }
            })

            console.error(error)
        }
    })
}

function* deleteUserSaga(){
    yield takeLatest(deleteUser.type, function* (action: PayloadAction<any>) {
        const {response, error} = yield call(SuperFetch, {
            method: "delete",
            authenticate: true,
            endpoint: endpoints.deleteUser + "/" + action.payload
        })

        if(response){
            yield put({
                type: getAllUsers.type
            })

            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "success",
                    message: "L'utente è stato eliminato!"
                }
            })
        }else if(error){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "error",
                    message: "Errore inaspettato!"
                }
            })

            console.error(error)
        }
    })
}

function* getAllUsersSaga(){
    yield takeLatest(getAllUsers.type, function* (action: PayloadAction<any>) {
        const {response, error} = yield call(SuperFetch, {
            method: "get",
            authenticate: true,
            endpoint: endpoints.getAllUsers
        })

        if(response){
            yield put({
                type: getAllUsersSuccess.type,
                payload: response
            })
        }else if(error){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "error",
                    message: "Errore inaspettato!"
                }
            })

            console.error(error)
        }
    })
}

function* getUserSaga(){
    yield takeLatest(getUser.type, function* (action: PayloadAction<string>) {
        const {response, error} = yield call(SuperFetch, {
            method: "get",
            authenticate: true,
            endpoint: endpoints.getUser + "/" + action.payload
        })

        if(response){
            yield put({
                type: getUserSuccess.type,
                payload: response
            })
        }else if(error){
            yield put({
                type: openSnackbar.type,
                payload: {
                    type: "error",
                    message: "Errore inaspettato!"
                }
            })

            console.error(error)
        }
    })
}



export default function* usersSaga(){
    yield all([
        fork(createUserSaga),
        fork(getAllUsersSaga),
        fork(getUserSaga),
        fork(updateUserSaga),
        fork(deleteUserSaga)
    ])
}