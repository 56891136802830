import {createSlice} from '@reduxjs/toolkit'
import {revertAll} from "../store";

// Define a type for the slice state
interface LanguageSwitcherState {
    language: string
}

// Define the initial state using that type
const initialState: LanguageSwitcherState = {
    language: "it"
}

export const languageSwitcherSlice = createSlice({
    name: 'languageSwitcher',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
})

export default languageSwitcherSlice.reducer;