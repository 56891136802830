import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom"
import Sidebar from "components/sidebar/Sidebar";
import { Container, styled, useMediaQuery, useTheme } from "@mui/material";
import Topbar from "components/topbar/Topbar";


const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    marginTop: "64px",
    marginLeft: 0,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(7),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    backgroundColor: "white",
    display: "flex",
    height: "calc(100% - 64px)",

    [theme.breakpoints.down('lg')]: {
        marginLeft: `0px`,
    },

    [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        height: "calc(100% - 56px)",
        marginTop: "56px",
    },
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    ...(open && {
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    }),
}));

function Home() {

    const customTheme = useTheme()
    const matches = useMediaQuery(customTheme.breakpoints.up('lg'))

    const [open, setOpen] = useState<boolean>(matches)


    useEffect(() => {
        if (!matches) {
            setOpen(false)
        } else if (matches) {
            setOpen(true)
        }
    }, [matches])


    useEffect(() => {


    }, [])

    return (
        <>
            <Topbar drawerWidth={drawerWidth} open={open} setOpen={setOpen} />
            <Sidebar drawerWidth={drawerWidth} open={open} setOpen={setOpen} />
            <Main className={"main"} open={open}>
                <Container maxWidth={"xl"} disableGutters sx={{flex: 1}}>
                    <Outlet />
                </Container>
            </Main>
        </>
    );
}

export default Home;