import React, {useDeferredValue, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom'
import {SidebarElementProps} from "../../interfaces/Interfaces";
import {Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText, styled} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RecursiveSidebar from "./RecursiveSidebar";


const ListWrapper = styled('div')(({theme}) => ({
    ".selected": {
        backgroundColor: "#EFF5F9",
    },
    ".selected .MuiListItemIcon-root svg": {
        color: theme.palette.primary.dark
    },
    ".selected .MuiListItemText-root .MuiListItemText-primary": {
        color: theme.palette.primary.dark
    },
    ".selected .MuiListItemText-root .MuiListItemText-secondary": {
        color: theme.palette.primary.dark,
        opacity: "60%"
    },
}));


function SidebarElement(props: SidebarElementProps) {

    const {element} = props

    const location = useLocation()
    const navigate = useNavigate()

    let padding: number = props.padding

    const [open, setOpen] = useState<boolean>()


    return (
        <ListWrapper>
            <ListItemButton
                className={location.pathname === element.path ? "selected" : ""}
                sx={{pl: padding}}
                onClick={() => {
                    setOpen(!open)
                    if (element.path) {
                        navigate(element.path)
                    }
                }}>
                <ListItemIcon>
                    {element.icon && element.icon}
                </ListItemIcon>
                <ListItemText primary={element.primaryText} secondary={element.secondaryText}/>
                {element.nested ? open ? <ExpandLess/> : <ExpandMore/> : null}
            </ListItemButton>
            {element.nested && element.nested.length > 0 &&
                <>
                    <Collapse in={open} timeout="auto">
                        <List component="div" disablePadding dense>
                            <RecursiveSidebar menuItems={element.nested} key={element.primaryText} padding={padding + 2}/>
                        </List>
                    </Collapse>
                </>
            }
            {element.divider && <Divider/>}
        </ListWrapper>

    );
}

export default SidebarElement;