import React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {privateRoutes, ROUTES} from "router/route.constants";
import LoginPage from "pages/LoginPage";
import Home from "pages/Home";
import {useAppSelector} from "./redux/hooks";

function Router() {
    const role = useAppSelector(state => state.auth.role)
    const token = useAppSelector(state => state.auth.token)

    return (
            <BrowserRouter>
                <Routes>
                    <Route path={ROUTES.LOGIN} element={<LoginPage />} />

                    {token && <Route element={<Home />}>
                        {privateRoutes.map((element, index) => {
                            return (
                                element.roles && <Route key={index} element={element.component} path={element.path} />
                            )
                        })}
                    </Route> }

                    <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
                </Routes>
            </BrowserRouter>
    );
}

export default Router;
