import React from "react";
import {RouteConstants} from "../interfaces/Interfaces";
import TestPage from "../pages/TestPage";
import SosDashboard from "../pages/SosDashboard";
import UsersPage from "../pages/UsersPage";
import UserForm from "../pages/UserForm";
import SosHistory from "../pages/SosHistory";
import SettingsPage from "../pages/SettingsPage";

export const ROLES = {
    SUPER_ADMIN: "ROLE_SUPER_ADMIN",
    EMPLOYEE: "ROLE_EMPLOYEE",
}

export const ROUTES = {
    LOGIN: "/login",
    SOS: "/sos",
    USERS: "/users",
    USER: "/user",
    USER_MODIFY: "/user/:id",
    SOS_HYSTORY: "/sos/history",
    SETTINGS: "/settings"
}

export const privateRoutes: RouteConstants[] = [
    {
        path: ROUTES.SOS,
        component: <SosDashboard/>,
        roles: [ROLES.SUPER_ADMIN, ROLES.EMPLOYEE]
    },
    {
        path: ROUTES.USERS,
        component: <UsersPage/>,
        roles: [ROLES.SUPER_ADMIN]
    },
    {
        path: ROUTES.USER,
        component: <UserForm/>,
        roles: [ROLES.SUPER_ADMIN]
    },
    {
        path: ROUTES.USER_MODIFY,
        component: <UserForm/>,
        roles: [ROLES.SUPER_ADMIN]
    },
    {
        path: ROUTES.SOS_HYSTORY,
        component: <SosHistory/>,
        roles: [ROLES.SUPER_ADMIN]
    },
    {
        path: ROUTES.SETTINGS,
        component: <SettingsPage/>,
        roles: [ROLES.SUPER_ADMIN]
    },
]