import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {revertAll, RootState} from "../store";
import {Device, Sos} from "../../interfaces/Interfaces";

interface sosState {
    channel: any,
    deviceList: Device[],
    sosList: Sos[]
}

// Define the initial state using that type
const initialState: sosState = {
    channel: null,
    deviceList: [],
    sosList: []
}

function sortFunction(d1, d2){
    if (d1.lastSos && d2.lastSos && d1.lastSos.dateCreated > d2.lastSos.dateCreated) {
        return -1
    }
    if (d1.lastSos && d2.lastSos && d1.lastSos.dateCreated < d2.lastSos.dateCreated) {
        return 1
    }
    if (d1.lastSos && !d2.lastSos) {
        return -1
    }
    if (!d1.lastSos && d2.lastSos) {
        return 1
    }

    return 0
}


export const sosSlice = createSlice({
    name: 'sos',
    initialState,
    reducers: {
        subscribe: (state, action) => {},
        unsubscribe: (state, action) => {},
        setChannel: (state, action) => {
            state.channel = action.payload
        },
        newSos: (state, action: PayloadAction<any>) => {
            let index = state.deviceList.findIndex(it => it.id === action.payload.device.id)
            state.deviceList[index].lastSos = action.payload
            state.deviceList.sort(sortFunction)
        },
        reconnectToSse: (state, action) => {},
        getDeviceRegistered: () => {},
        getDeviceRegisteredSuccess: (state, action: PayloadAction<Device[]>) => {
            state.deviceList = action.payload
            state.deviceList.sort(sortFunction)
        },
        closeSos: (state, action) => {},
        newSosClosed: (state, action:PayloadAction<any>) => {
            let index = state.deviceList.findIndex(it => it.id === action.payload.device.id)
            state.deviceList[index].lastSos = undefined
            state.deviceList.sort(sortFunction)
        },
        deleteDevice: (state, action) => {},
        deleteDeviceSuccess: (state, action) => {
            let index = state.deviceList.findIndex(it => it.id === action.payload)
            state.deviceList.splice(index, 1)
            state.deviceList.sort(sortFunction)
        },
        getAll: () => {},
        getAllSuccess: (state, action) => {
            state.sosList = action.payload
        },
    },
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
})


export const {
    subscribe,
    unsubscribe,
    setChannel,
    newSos,
    newSosClosed,
    reconnectToSse,
    getDeviceRegistered,
    getDeviceRegisteredSuccess,
    closeSos,
    deleteDevice,
    deleteDeviceSuccess,
    getAll,
    getAllSuccess
} = sosSlice.actions

const channel = (state: RootState) => state.sos.channel

const channelSelector = createSelector(channel, (channel) => channel)

export { channelSelector }


export default sosSlice.reducer