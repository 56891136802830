import {createSlice} from '@reduxjs/toolkit'
import { AlertColor } from '@mui/material';
import {revertAll} from "../store";

interface SnackbarState {
    open: boolean,
    type: AlertColor,
    message: string
}

// Define a type for the slice state
interface appState {
    snackbarState: SnackbarState,
    audioContext: AudioContext,
    audioEnabled: boolean
}

// Define the initial state using that type
const initialState: appState = {
    snackbarState: {
        open: false,
        type: null,
        message: null
    },
    audioContext: null,
    audioEnabled: false
}


export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        openSnackbar: (state, action) => {
            state.snackbarState = {
                open: true,
                type: action.payload.type,
                message: action.payload.message
            }
        },
        closeSnackbar: (state, action) => {
            state.snackbarState = {
                ...state.snackbarState,
                open: false
            }
        },
        setAudioContext: (state, action) => {
            state.audioContext = action.payload
        },
        setAudioEnabled: (state, action) => {
            state.audioEnabled = true
        }
    },
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
})


export const {
    openSnackbar,
    closeSnackbar,
    setAudioContext,
    setAudioEnabled
} = appSlice.actions


export default appSlice.reducer