import esMessages from '../locales/es_ES.json';

const Eslang = {
    messages: {
        ...esMessages
    },
    locale: 'es',
    data: require('@formatjs/intl-pluralrules/locale-data/es')
};

export default Eslang;