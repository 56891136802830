import React from 'react';
import './App.css';
import store from "./redux/store";
import {Provider} from "react-redux";
import Router from "./router"
import AppProvider from './AppProvider';

function App() {

    return (
        <Provider store={store}>
            <AppProvider>
                <Router/>
            </AppProvider>
        </Provider>
    );
}

export default App;